import {
  Box,
  Button,
  Heading,
  Table,
  TableCellProps,
  TableColumnHeaderProps,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
  useDisclosure,
} from '@chakra-ui/react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { useGetPayoutConditionsQuery } from '../../../generated/graphql';
import {
  ResponsiveModal,
  ResponsiveModalBodyBox,
  ResponsiveModalCloseButton,
  ResponsiveModalContent,
  ResponsiveModalOverlay,
  ResponsiveModalStickyHeaderBox,
} from '../../Layout/ResponsiveModal';

const TableHeader: React.FC<TableColumnHeaderProps> = (props) => {
  return (
    <Th
      verticalAlign={'top'}
      pl={0}
      pr={1}
      width={'50%'}
      fontSize={'16px'}
      fontWeight={700}
      lineHeight={'20px'}
      textColor={'coldGray.900'}
      letterSpacing={'0px'}
      textTransform={'unset'}
      {...props}
    />
  );
};

const TableData: React.FC<TableCellProps> = (props) => {
  return <Td pl={0} pr={2} {...props} />;
};

export const FeesAndConditionsModalButton: React.FC = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { data } = useGetPayoutConditionsQuery();

  const feesData = React.useMemo(() => {
    return data?.finances.payoutConditions;
  }, [data]);

  const { t } = useTranslation(['feesAndConditions']);

  // Texts and values might differ from classic. This is intended and should *NOT* be "fixed".

  return (
    <Button variant={'ghost'} onClick={onOpen}>
      {t('feesAndConditions:button.BedingungenUndGebuhren')}
      <ResponsiveModal isOpen={isOpen} onClose={onClose} preferredSize="xl">
        <ResponsiveModalOverlay />
        <ResponsiveModalContent maxW={'567px'}>
          <ResponsiveModalStickyHeaderBox shadow={'unset'}>
            <Heading size={'lg'} fontWeight={700} lineHeight={6}>
              {t('feesAndConditions:header.BedingungenUndGebuhren')}
            </Heading>
            <ResponsiveModalCloseButton />
          </ResponsiveModalStickyHeaderBox>

          <ResponsiveModalBodyBox>
            <VStack width={'100%'} spacing={0}>
              <Heading size={'lg'} fontWeight={700} lineHeight={5}>
                {t('feesAndConditions:header.Mindestbetrag')}
              </Heading>
              <Heading
                fontSize={'32px'}
                lineHeight={'48px'}
                fontWeight={700}
                letterSpacing={'0px'}
              >
                {t('feesAndConditions:header.HeaderMinValue', {
                  value: feesData?.minAmountBankDE,
                })}
              </Heading>
            </VStack>

            <TableContainer p={4} whiteSpace={'unset'}>
              <Table variant={'simple'} maxWidth={'full'} overflow={'hidden'}>
                <Thead>
                  <Tr>
                    <TableHeader pr={7}>
                      {t('feesAndConditions:subHeader.BankUberweisung')}
                    </TableHeader>
                    <TableHeader>
                      {t('feesAndConditions:subHeader.Gebuhren')}
                      <Text fontSize={'14px'} as={'p'} fontWeight={400}>
                        {t('feesAndConditions:subHeader.ProAuszahlung')}
                      </Text>
                    </TableHeader>
                  </Tr>
                </Thead>
                <Tbody fontSize={'14px'}>
                  <Tr>
                    <TableData>
                      {t('feesAndConditions:text.InnerhalbDeCh')}
                    </TableData>
                    <TableData>
                      {t('feesAndConditions:text.Kostenfrei')}
                    </TableData>
                  </Tr>
                  <Tr>
                    <TableData>
                      {t('feesAndConditions:text.InnerhalbEu')}
                    </TableData>
                    <TableData>
                      {t('feesAndConditions:feeValues.FeeBankEu', {
                        value: feesData?.feeBankEU,
                      })}
                    </TableData>
                  </Tr>
                  <Tr>
                    <TableData>
                      {t('feesAndConditions:text.AusserhalbSepaRaum')}
                    </TableData>
                    <TableData>
                      {t('feesAndConditions:feeValues.FeeBankOutsideSepa', {
                        value: feesData?.feeBank3L,
                      })}
                    </TableData>
                  </Tr>
                </Tbody>
              </Table>
            </TableContainer>
            <TableContainer p={4} whiteSpace={'unset'}>
              <Table variant={'simple'} overflow={'hidden'}>
                <Thead>
                  <Tr>
                    <TableHeader>
                      {t('feesAndConditions:subHeader.Paxum')}
                    </TableHeader>
                    <TableHeader>
                      {t('feesAndConditions:subHeader.Gebuhren')}
                      <Text fontSize={'14px'} as={'p'} fontWeight={400}>
                        {t('feesAndConditions:subHeader.ProAuszahlung')}
                      </Text>
                    </TableHeader>
                  </Tr>
                </Thead>
                <Tbody fontSize={'14px'}>
                  <Tr>
                    <TableData>
                      {t('feesAndConditions:text.NurVerfugbarAusserhalbDeCh')}
                    </TableData>
                    <TableData>
                      {t('feesAndConditions:feeValues.FeePaxum', {
                        value: feesData?.feePaxum,
                      })}
                    </TableData>
                  </Tr>
                </Tbody>
              </Table>
            </TableContainer>
            <Box p={4} textStyle={'bodySm'}>
              <Text>
                {t(
                  'feesAndConditions:text.FurAuszahlungenUnterDemMindestbetragWerden10GebuhrBerechnet',
                  { value: feesData?.feeUnderMinAmount }
                )}
              </Text>
              <Text>
                {t(
                  'feesAndConditions:text.AbhangigVomBankinstitutAnbieterKonnenWeitereGebuhrenAnfallen'
                )}
              </Text>
            </Box>
          </ResponsiveModalBodyBox>
        </ResponsiveModalContent>
      </ResponsiveModal>
    </Button>
  );
};
