import { SubHeader, SubHeaderTitle } from '@campoint/odi-ui';
import { Container, VStack } from '@chakra-ui/react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { FluentPageLayout } from '../../../components/Layout/FluentPageLayout';
import { LayoutHintsVStack } from '../../../components/Layout/LayoutHintsVStack';
import { UpdateTaxInformationExpiredHintSlot } from '../../../components/shared/UpdateTaxInformationExpiredHintSlot/UpdateTaxInformationExpiredHintSlot';
import { ProfileStatusEnum } from '../../../generated/graphql';
import { useFinanceService } from '../../../provider/FinanceService/FinanceService';
import { NavigationBlockProvider } from '../../../provider/NavigationBlockProvider';
import { PriorToPayoutSetupSection } from '../../FinancePage/components/PriorToPayoutSetupSection/PriorToPayoutSetupSection';
import PayoutDocumentsAddressSection from './PayoutDocumentsAddressSection/PayoutDocumentsAddressSection';
import { PayoutDocumentsPayoutSection } from './PayoutDocumentsPayoutSection/PayoutDocumentsPayoutSection';
import PayoutDocumentsTaxSection from './PayoutDocumentsTaxSection/PayoutDocumentsTaxSection';

export const PayoutDocumentsPage: React.FC = () => {
  const { t } = useTranslation(['payoutDocuments']);
  const {
    loading,
    payoutWizardStatus,
    isPayoutWizardFinished,
    action: { triggerRefetch },
    payableAmount,
    showTin,
  } = useFinanceService();
  React.useEffect(() => {
    triggerRefetch();
  }, [triggerRefetch]);

  const isPayoutWizardActiveOverride = payableAmount >= 0.5;
  const spacing = { base: 4, lg: 8 };
  return (
    <NavigationBlockProvider>
      <FluentPageLayout isContentLoading={loading}>
        <SubHeader>
          <Container px={0} maxW={'container.xl'}>
            <SubHeaderTitle>
              {t('payoutDocuments:heading.Auszahlung')}
            </SubHeaderTitle>
          </Container>
        </SubHeader>
        <Container maxW="container.xl" p={0}>
          <VStack w={'full'} spacing={spacing} mb={spacing}>
            <LayoutHintsVStack>
              <UpdateTaxInformationExpiredHintSlot
                outerStackProps={{ pt: spacing }}
              />
              {payoutWizardStatus === ProfileStatusEnum.Incomplete && (
                <PriorToPayoutSetupSection
                  isSetupPayoutWizardActive={isPayoutWizardActiveOverride}
                  outerStackProps={{ pt: spacing }}
                />
              )}
            </LayoutHintsVStack>
            {isPayoutWizardFinished && (
              <>
                {showTin && <PayoutDocumentsTaxSection />}
                <PayoutDocumentsPayoutSection />
                <PayoutDocumentsAddressSection />
              </>
            )}
          </VStack>
        </Container>
      </FluentPageLayout>
    </NavigationBlockProvider>
  );
};
